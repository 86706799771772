import ChevronRightIcon from 'assets/chevron-right-solid.svg';
import { ReactComponent as CircleCheckIcon } from 'assets/circle-check-solid.svg';

import { TreeNode } from 'components/TreeNode';

import { ITreeProps } from 'types/ITree.ts';

import './Tree.styles.scss';

export const Tree = ({
  treeData,
  loadData,
  onCheck,
  checkedClassesIds,
  parentClass,
}: ITreeProps) => {
  return (
    <div className="tree__container">
      {parentClass ? (
        <div className="tree__parent-class-container">
          <div className="tree__parent-class-check-container">
            <CircleCheckIcon className="tree__parent-class-check" />
          </div>

          <div className="tree__parent-class-title-container">
            <div className="tree__parent-class-icon-container">
              <span className="tree__parent-class-switcher">
                <img src={ChevronRightIcon} alt="chevron-right" />
              </span>
            </div>

            <p className="tree__parent-class-title text-ellipsis">
              {parentClass.name}
            </p>
          </div>
        </div>
      ) : null}

      {treeData.map((treeNodeData) => (
        <TreeNode
          treeNodeData={treeNodeData}
          key={treeNodeData.id}
          loadData={loadData}
          onCheck={onCheck}
          level={parentClass ? 1 : 0}
          checkedClassesIds={checkedClassesIds}
        />
      ))}
    </div>
  );
};
