import { EObjectClassFieldType } from 'types/ObjectClassFieldType';

export const mapClassFieldType = (fieldType: EObjectClassFieldType) => {
  switch (fieldType) {
    case EObjectClassFieldType.Int:
      return 'Integer';
    case EObjectClassFieldType.Float:
      return 'Decimal';
    case EObjectClassFieldType.Bool:
      return 'Checkbox';
    case EObjectClassFieldType.String:
      return 'Text';
    case EObjectClassFieldType.Enum:
      return 'Single select';
    case EObjectClassFieldType.Set:
      return 'Multi select';
    case EObjectClassFieldType.Email:
      return 'Email';
    case EObjectClassFieldType.Phone:
      return 'Phone';
    case EObjectClassFieldType.Date:
      return 'Date';
    case EObjectClassFieldType.Datetime:
      return 'Date/Time';
    case EObjectClassFieldType.Time:
      return 'Time';
    case EObjectClassFieldType.Url:
      return 'URL';
    case EObjectClassFieldType.Json:
      return 'JSON';
    case EObjectClassFieldType.Document:
      return 'Document';
    case EObjectClassFieldType.User:
      return 'User';
    default:
      return 'Text';
  }
};
