import { createBrowserRouter, createMemoryRouter } from 'react-router-dom';

import { Layout } from 'components/Layout';
import { RequireAuth } from 'components/RequireAuth';

import { HomePage } from 'views/HomePage';
import { LoginErrorPage } from 'views/LoginErrorPage';
import { LoginPage } from 'views/LoginPage';
import { ManifestPage } from 'views/ManifestPage';
import { NotFoundPage } from 'views/NotFoundPage';
import { RelationalClassesPage } from 'views/RelationalClassesPage';
import { SettingsPage } from 'views/SettingsPage';

import { browserRoutes, routes } from './routes';

export const router = createMemoryRouter([
  {
    path: routes.home,
    element: <RequireAuth />,
    children: [
      {
        element: <Layout />,
        children: [
          {
            index: true,
            element: <HomePage />,
          },
          {
            path: routes.settings,
            element: <SettingsPage />,
          },
          {
            path: routes.relationalClasses,
            element: <RelationalClassesPage />,
          },
        ],
      },
    ],
  },
  {
    path: routes.login,
    element: <Layout hideNavButton />,
    children: [{ index: true, element: <LoginPage /> }],
  },
  {
    path: routes.loginError,
    element: <Layout hideNavButton />,
    children: [{ index: true, element: <LoginErrorPage /> }],
  },
]);

export const browserRouter = createBrowserRouter([
  {
    path: browserRoutes.getManifest,
    element: <Layout hideNavButton />,
    children: [{ index: true, element: <ManifestPage /> }],
  },
  {
    path: '*',
    element: <Layout hideNavButton />,
    children: [{ path: '*', element: <NotFoundPage /> }],
  },
]);
