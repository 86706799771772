import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import 'scss/main.scss';

import { initializeIcons } from '@fluentui/font-icons-mdl2';

import { browserRouter } from 'router';

import { App } from './App';

initializeIcons();

const renderApp = () => {
  createRoot(document.getElementById('root') as HTMLElement).render(<App />);
};

const renderBrowserApp = () => {
  createRoot(document.getElementById('root') as HTMLElement).render(
    <RouterProvider router={browserRouter} />
  );
};

void Office.onReady((info) => {
  if (info.host === Office.HostType.Word) {
    renderApp();
  } else {
    renderBrowserApp();
  }
});
