import { useCallback, useState } from 'react';

import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

import { Drawer } from 'components/Drawer';
import { FunctionsListItem } from 'components/FunctionsListItem';
import { functionsData } from 'components/FunctionsTabPanel/functionsData';

import { useStore } from 'store';

import { insertTextToDocument } from 'utils/insertTextToDocument';

import './FunctionsTabPanel.styles.scss';

export const FunctionsTabPanel = () => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedFunction = useStore((state) => state.selectedFunction);

  const toggleDrawer = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen]);

  return (
    <section className="functions-list">
      {functionsData.map((functionData) => (
        <FunctionsListItem
          functionData={functionData}
          toggleDrawer={toggleDrawer}
        />
      ))}

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="bottom"
        size="80vh"
      >
        <div className="functions__drawer-container">
          <div className="functions__drawer-container-header">
            <p>
              <b>{selectedFunction?.name}</b> reference
            </p>
            <PrimaryButton
              onClick={() =>
                insertTextToDocument(selectedFunction?.code?.join('\n'))
              }
            >
              Insert
            </PrimaryButton>
          </div>

          <div className="functions__drawer-container-body">
            <p className="mb-20 functions__description">
              {selectedFunction?.description}
            </p>
            {selectedFunction?.example?.length ? (
              <>
                <p className="mb-20">Example:</p>
                <div
                  style={{ whiteSpace: 'pre-wrap' }}
                  className="functions__code-block"
                >
                  {selectedFunction?.example?.join('\n')}
                </div>
              </>
            ) : null}
          </div>

          <div className="functions__drawer-container-footer">
            <DefaultButton onClick={toggleDrawer}>Hide reference</DefaultButton>
          </div>
        </div>
      </Drawer>
    </section>
  );
};
