import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { OBJECT_CLASS_ENDPOINT } from 'constants/endpoints';

import { useAxiosPrivate } from 'hooks/api/useAxiosPrivate';

import { IObjectClass } from 'types/IObjectClass';

interface IRequestParams {
  axiosInstance: AxiosInstance;
  classId?: string | number | null;
}
export const getObjectClassRequest = async ({
  axiosInstance,
  classId,
}: IRequestParams) => {
  const url = OBJECT_CLASS_ENDPOINT.replace(
    ':class_id',
    classId ? classId.toString() : ''
  );
  const response = await axiosInstance.get<IObjectClass>(url);

  return response?.data ?? {};
};

export const useObjectClassQuery = (classId?: string | number | null) => {
  const { axiosInstance } = useAxiosPrivate();

  return useQuery<IObjectClass>({
    queryKey: ['objectClass', classId, axiosInstance.getUri()],
    queryFn: () => getObjectClassRequest({ axiosInstance, classId }),
    enabled: !!classId,
  });
};
