export const createManifestString = (applicationId: string): string => {
  return (
    '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n' +
    '<OfficeApp xmlns="http://schemas.microsoft.com/office/appforoffice/1.1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bt="http://schemas.microsoft.com/office/officeappbasictypes/1.0" xmlns:ov="http://schemas.microsoft.com/office/taskpaneappversionoverrides" xsi:type="TaskPaneApp">\n' +
    `  <Id>${applicationId}</Id>\n` +
    '  <Version>1.0.0.0</Version>\n' +
    '  <ProviderName>Autologyx</ProviderName>\n' +
    '  <DefaultLocale>en-US</DefaultLocale>\n' +
    '  <DisplayName DefaultValue="Catalyst Word Add-in"/>\n' +
    '  <Description DefaultValue="Automate document production!"/>\n' +
    '  <IconUrl DefaultValue="https://office-addin.autologyx.com/ALX_Logo32.png"/>\n' +
    '  <HighResolutionIconUrl DefaultValue="https://office-addin.autologyx.com/ALX_Logo64.png"/>\n' +
    '  <SupportUrl DefaultValue="https://ng-autologyx.zendesk.com/hc/en-us/articles/11721025596701-MS-Word-add-in"/>\n' +
    '  <AppDomains>\n' +
    '    <AppDomain>https://office-addin.autologyx.com</AppDomain>\n' +
    '  </AppDomains>\n' +
    '  <Hosts>\n' +
    '    <Host Name="Document"/>\n' +
    '  </Hosts>\n' +
    '  <DefaultSettings>\n' +
    '    <SourceLocation DefaultValue="https://office-addin.autologyx.com"/>\n' +
    '  </DefaultSettings>\n' +
    '  <Permissions>ReadWriteDocument</Permissions>\n' +
    '  <VersionOverrides xmlns="http://schemas.microsoft.com/office/taskpaneappversionoverrides" xsi:type="VersionOverridesV1_0">\n' +
    '    <Hosts>\n' +
    '      <Host xsi:type="Document">\n' +
    '        <DesktopFormFactor>\n' +
    '          <GetStarted>\n' +
    '            <Title resid="GetStarted.Title"/>\n' +
    '            <Description resid="GetStarted.Description"/>\n' +
    '            <LearnMoreUrl resid="GetStarted.LearnMoreUrl"/>\n' +
    '          </GetStarted>\n' +
    '          <FunctionFile resid="Commands.Url"/>\n' +
    '          <ExtensionPoint xsi:type="PrimaryCommandSurface">\n' +
    '            <OfficeTab id="TabHome">\n' +
    '              <Group id="CommandsGroup">\n' +
    '                <Label resid="CommandsGroup.Label"/>\n' +
    '                <Icon>\n' +
    '                  <bt:Image size="16" resid="Icon.16x16"/>\n' +
    '                  <bt:Image size="32" resid="Icon.32x32"/>\n' +
    '                  <bt:Image size="80" resid="Icon.80x80"/>\n' +
    '                </Icon>\n' +
    '                <Control xsi:type="Button" id="TaskpaneButton">\n' +
    '                  <Label resid="TaskpaneButton.Label"/>\n' +
    '                  <Supertip>\n' +
    '                    <Title resid="TaskpaneButton.Label"/>\n' +
    '                    <Description resid="TaskpaneButton.Tooltip"/>\n' +
    '                  </Supertip>\n' +
    '                  <Icon>\n' +
    '                    <bt:Image size="16" resid="Icon.16x16"/>\n' +
    '                    <bt:Image size="32" resid="Icon.32x32"/>\n' +
    '                    <bt:Image size="80" resid="Icon.80x80"/>\n' +
    '                  </Icon>\n' +
    '                  <Action xsi:type="ShowTaskpane">\n' +
    '                    <TaskpaneId>ButtonId1</TaskpaneId>\n' +
    '                    <SourceLocation resid="Taskpane.Url"/>\n' +
    '                  </Action>\n' +
    '                </Control>\n' +
    '              </Group>\n' +
    '            </OfficeTab>\n' +
    '          </ExtensionPoint>\n' +
    '        </DesktopFormFactor>\n' +
    '      </Host>\n' +
    '    </Hosts>\n' +
    '    <Resources>\n' +
    '      <bt:Images>\n' +
    '        <bt:Image id="Icon.16x16" DefaultValue="https://office-addin.autologyx.com/ALX_Logo16.png"/>\n' +
    '        <bt:Image id="Icon.32x32" DefaultValue="https://office-addin.autologyx.com/ALX_Logo32.png"/>\n' +
    '        <bt:Image id="Icon.80x80" DefaultValue="https://office-addin.autologyx.com/ALX_Logo80.png"/>\n' +
    '      </bt:Images>\n' +
    '      <bt:Urls>\n' +
    '        <bt:Url id="GetStarted.LearnMoreUrl" DefaultValue="https://go.microsoft.com/fwlink/?LinkId=276812"/>\n' +
    '        <bt:Url id="Commands.Url" DefaultValue="https://office-addin.autologyx.com/commands.html"/>\n' +
    '        <bt:Url id="Taskpane.Url" DefaultValue="https://office-addin.autologyx.com"/>\n' +
    '      </bt:Urls>\n' +
    '      <bt:ShortStrings>\n' +
    '        <bt:String id="GetStarted.Title" DefaultValue="Get started with the Autologyx document automation add-in."/>\n' +
    '        <bt:String id="CommandsGroup.Label" DefaultValue="Template Editor"/>\n' +
    '        <bt:String id="TaskpaneButton.Label" DefaultValue="ALX Catalyst"/>\n' +
    '      </bt:ShortStrings>\n' +
    '      <bt:LongStrings>\n' +
    '        <bt:String id="GetStarted.Description" DefaultValue="Autologyx has loaded successfully."/>\n' +
    '        <bt:String id="TaskpaneButton.Tooltip" DefaultValue="Insert merge fields from Autologyx Catalyst classes."/>\n' +
    '      </bt:LongStrings>\n' +
    '    </Resources>\n' +
    '    <WebApplicationInfo>\n' +
    `      <Id>${applicationId}</Id>\n` +
    `      <Resource>api://office-addin.autologyx.com/${applicationId}</Resource>\n` +
    '      <Scopes>\n' +
    '        <Scope>openid</Scope>\n' +
    '        <Scope>profile</Scope>\n' +
    '      </Scopes>\n' +
    '    </WebApplicationInfo>\n' +
    '  </VersionOverrides>\n' +
    '</OfficeApp>'
  );
};
