import { IObjectClassField } from 'types/IObjectClassField.ts';
import { IMergeFieldsClassFilter } from 'types/ISelectOption.ts';

export const formatInsertedObjectClassField = (
  objectClassField?: IObjectClassField,
  mergeFieldsClassFilter?: IMergeFieldsClassFilter
) => {
  if (!objectClassField) return '';

  if (mergeFieldsClassFilter?.isRelationalClass === true) {
    return `{{ record.related_records_${mergeFieldsClassFilter.id}[0].field_${objectClassField.alias} }}`;
  }

  return `{{ record.field_${objectClassField.alias} }}`;
};
