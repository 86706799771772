import { AxiosError } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { shallow } from 'zustand/shallow';

import { Link, Modal } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  DefaultButton,
  PrimaryButton,
  IconButton,
} from '@fluentui/react/lib/Button';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { ITextField } from '@fluentui/react/lib/TextField';
import { TextField } from '@fluentui/react/lib/TextField';

import { ObjectClassSelect } from 'components/ObjectClassSelect';

import { useApiVersionQuery } from 'hooks/api/useApiVersionQuery';
import { useObjectClassQuery } from 'hooks/api/useObjectClassQuery';
import { useObjectClassesQuery } from 'hooks/api/useObjectClassesQuery';
import { useRelationalClassesQuery } from 'hooks/api/useRelationalClassesQuery.ts';
import { useSelectedRelationalClassesQueries } from 'hooks/api/useSelectedRelationalClassesQueries.ts';
import { useUpdateUserPreferencesMutation } from 'hooks/api/useUpdateUserPreferencesMutation';
import { useUploadFileMutation } from 'hooks/api/useUploadFileMutation';
import { useUserPreferencesQuery } from 'hooks/api/useUserPreferencesQuery';
import { useVerifyDocumentTemplateMutation } from 'hooks/api/useVerifyDocumentTemplateMutation';

import { routes } from 'router';

import { useStore } from 'store';

import { IErrorResponse } from 'types/IErrorResponse';
import { ISelectOption } from 'types/ISelectOption';

import { deleteCustomPropertiesFromDocument } from 'utils/deleteCustomPropertiesFromDocument';
import { getErrorMessageFromResponse } from 'utils/getErrorMessageFromResponse';
import { getWordDocumentBlob } from 'utils/getWordDocumentBlob';

import './SettingsPage.styles.scss';

const tooltipContent =
  'The currently open document has been configured for the selected Catalyst domain and Object Class. Neither can be changed as long as this document is open.';

export const SettingsPage = () => {
  const navigate = useNavigate();
  const inputRef = useRef<ITextField | null>(null);
  const [searchPhrase, setSearchPhrase] = useState<string>('');

  // Store
  const [
    apiBaseUrl,
    setSelectedObjectClassField,
    setApiBaseUrl,
    setAccessToken,
    setRefreshToken,
    documentCatalystClassId,
    documentCatalystDomain,
    setShowLoaderOverlay,
    selectedObjectClass,
    setSelectedObjectClass,
  ] = useStore(
    (state) => [
      state.apiBaseUrl,
      state.setSelectedObjectClassField,
      state.setApiBaseUrl,
      state.setAccessToken,
      state.setRefreshToken,
      state.documentCatalystClassId,
      state.documentCatalystDomain,
      state.setShowLoaderOverlay,
      state.selectedObjectClass,
      state.setSelectedObjectClass,
    ],
    shallow
  );

  // Modals
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [
    hideClearDocumentPropertiesDialog,
    { toggle: toggleClearDocumentPropertiesDialog },
  ] = useBoolean(true);
  const [
    isErrorModalOpen,
    { setTrue: showErrorModal, setFalse: hideErrorModal },
  ] = useBoolean(false);
  const [documentVerificationErrors, setDocumentVerificationErrors] = useState<
    string[]
  >([]);

  // Queries
  const { data: objectClasses, isLoading: isLoadingObjectClasses } =
    useObjectClassesQuery(searchPhrase);
  const { data: userPreferences, isLoading: isLoadingUserPreferences } =
    useUserPreferencesQuery();
  const {
    data: objectClassSavedInPreferences,
    isInitialLoading: isLoadingObjectClassSavedInPreferences,
  } = useObjectClassQuery(userPreferences?.selectedObjectClassId);
  const { data: apiVersion } = useApiVersionQuery();
  const { data: relationalClasses, isLoading: isLoadingRelationalClasses } =
    useRelationalClassesQuery(selectedObjectClass?.value?.toString());
  const {
    data: selectedRelationalClasses,
    isLoading: isLoadingSelectedRelationalClasses,
  } = useSelectedRelationalClassesQueries(
    userPreferences?.selectedRelationalClassesIds
  );

  // Mutations
  const { mutateAsync: updateUserPreferences } =
    useUpdateUserPreferencesMutation();
  const { mutateAsync: uploadFile } = useUploadFileMutation();
  const { mutateAsync: verifyDocumentTemplate } =
    useVerifyDocumentTemplateMutation();

  const areLocalAndRemoteClassesEqual =
    selectedObjectClass?.value === userPreferences?.selectedObjectClassId;

  const isSaveButtonDisabled =
    selectedObjectClass === null ||
    documentCatalystClassId !== undefined ||
    areLocalAndRemoteClassesEqual;

  const isAddRelationalClassesButtonDisabled =
    isLoadingRelationalClasses ||
    !relationalClasses ||
    relationalClasses?.length === 0;

  const showRelationalClassesList =
    !isLoadingSelectedRelationalClasses &&
    selectedRelationalClasses.length > 0 &&
    areLocalAndRemoteClassesEqual;

  useEffect(() => {
    if (
      !isLoadingObjectClassSavedInPreferences &&
      objectClassSavedInPreferences &&
      !selectedObjectClass
    ) {
      setSelectedObjectClass({
        value: objectClassSavedInPreferences?.id,
        text: objectClassSavedInPreferences?.name,
      } as ISelectOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectClassSavedInPreferences]);

  const objectClassSelectOptions = useMemo(() => {
    if (
      !isLoadingObjectClasses &&
      !isLoadingUserPreferences &&
      !isLoadingObjectClassSavedInPreferences
    ) {
      if (selectedObjectClass && objectClasses) {
        const found = objectClasses.find(
          (objectClass) => objectClass.value === selectedObjectClass.value
        );

        return found ? objectClasses : [...objectClasses, selectedObjectClass];
      }

      return objectClasses ?? [];
    }

    return [];
  }, [
    isLoadingObjectClasses,
    isLoadingObjectClassSavedInPreferences,
    isLoadingUserPreferences,
    objectClasses,
    selectedObjectClass,
  ]);

  const onSubmit = async () => {
    const preferences = {
      selectedObjectClassId: selectedObjectClass?.value,
      selectedRelationalClassesIds: [],
    };

    try {
      await updateUserPreferences(preferences);
      setSelectedObjectClassField();
      navigate(routes.home);
    } catch {
      // TODO: add error handling
      console.log('error');
    }
  };

  const logout = () => {
    setAccessToken();
    setRefreshToken();
    setApiBaseUrl();
  };

  const handleVerifyDocumentTemplate = async () => {
    setShowLoaderOverlay(true);
    let fileToken;

    try {
      const blob = await getWordDocumentBlob();
      fileToken = await uploadFile(blob);
    } catch {
      toast.error('File upload failed. Try again later.');
    }

    if (fileToken) {
      try {
        const verificationMessage = await verifyDocumentTemplate(fileToken);

        if (Object.hasOwn(verificationMessage, 'detail')) {
          if (Array.isArray(verificationMessage.detail)) {
            setDocumentVerificationErrors(verificationMessage.detail);
            showErrorModal();

            toast.error('Document is invalid!');
          } else {
            toast.error(verificationMessage?.detail ?? 'Document is invalid!');
          }
        } else {
          toast.success('Document is valid!');
        }
      } catch (error) {
        const errorResponse = (error as AxiosError<IErrorResponse>)?.response;
        const errorMessage =
          errorResponse && getErrorMessageFromResponse(errorResponse);

        if (Array.isArray(errorMessage)) {
          toast.error(errorMessage[0]);
        } else {
          toast.error(errorMessage);
        }
      }
    }

    setShowLoaderOverlay(false);
  };

  const returnToHomepage = () => {
    if (
      !areLocalAndRemoteClassesEqual &&
      objectClassSavedInPreferences?.id &&
      objectClassSavedInPreferences?.name
    ) {
      setSelectedObjectClass({
        text: objectClassSavedInPreferences.name,
        value: objectClassSavedInPreferences.id,
      });
    }

    navigate(routes.home);
  };

  return (
    <>
      <div className="settings__container">
        <h1 className="settings__title">Settings</h1>
        <TextField
          label="Catalyst domain"
          defaultValue={apiBaseUrl}
          componentRef={inputRef}
          disabled
        />

        <div className="settings__button-container">
          <a
            data-tooltip-id="locked-document-tooltip"
            data-tooltip-hidden={documentCatalystDomain === undefined}
          >
            <PrimaryButton
              onClick={toggleHideDialog}
              disabled={documentCatalystDomain !== undefined}
            >
              Change domain
            </PrimaryButton>
          </a>
        </div>

        <div className="settings__object-class-select-wrapper">
          <label className="custom-ms-label">Object class</label>
          <ObjectClassSelect
            selectedObjectClass={selectedObjectClass}
            setSelectObjectClass={setSelectedObjectClass}
            setSearchPhrase={setSearchPhrase}
            objectClasses={objectClassSelectOptions}
            isLoading={isLoadingObjectClasses}
            disabled={documentCatalystClassId !== undefined}
          />
        </div>

        {showRelationalClassesList ? (
          <div className="settings__selected-relational-classes-list-container">
            <p>Related object classes in use</p>
            <ul className="selected-relational-classes-list">
              {selectedRelationalClasses?.map((selectedRelationalClass) => (
                <li className="text-ellipsis">
                  {selectedRelationalClass?.name}
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        <div className="settings__button-container">
          <PrimaryButton
            disabled={isAddRelationalClassesButtonDisabled}
            onClick={() =>
              navigate(
                routes.relationalClasses.replace(
                  ':classId',
                  selectedObjectClass?.value?.toString() ?? ''
                )
              )
            }
          >
            Add relational classes
          </PrimaryButton>
        </div>

        <div className="settings__button-container mb-16">
          <DefaultButton
            onClick={returnToHomepage}
            disabled={!userPreferences?.selectedObjectClassId}
          >
            Cancel
          </DefaultButton>

          <a
            data-tooltip-id="locked-document-tooltip"
            data-tooltip-hidden={documentCatalystClassId === undefined}
          >
            <PrimaryButton onClick={onSubmit} disabled={isSaveButtonDisabled}>
              Save
            </PrimaryButton>
          </a>
        </div>

        <div className="link-buttons__container">
          <a
            data-tooltip-id="locked-document-tooltip"
            data-tooltip-content="Select an object class to be able to verify the document template."
            data-tooltip-hidden={userPreferences?.selectedObjectClassId != null}
          >
            <Link
              onClick={handleVerifyDocumentTemplate}
              underline
              disabled={!userPreferences?.selectedObjectClassId}
            >
              Verify document template
            </Link>
          </a>

          <Link onClick={toggleClearDocumentPropertiesDialog} underline>
            Clear document properties
          </Link>
        </div>

        <div className="settings__version-info-container">
          <p className="settings__version-info">
            Catalyst Word Add-in version: {import.meta.env.VITE_APP_VERSION}
          </p>
          <p className="settings__version-info">
            Catalyst API version: {apiVersion?.version}
          </p>
        </div>
      </div>

      <Tooltip
        id="locked-document-tooltip"
        content={tooltipContent}
        variant="info"
        place="top"
        className="custom-tooltip"
      />

      {/* MODALS */}

      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Change Catalyst domain?',
          closeButtonAriaLabel: 'Close',
          subText:
            'Do you want to change the Catalyst domain you are connected to? You will need to select a new object class from the new domain.',
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={logout} text="Yes" />
          <DefaultButton onClick={toggleHideDialog} text="No" />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideClearDocumentPropertiesDialog}
        onDismiss={toggleClearDocumentPropertiesDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Clear document properties?',
          closeButtonAriaLabel: 'Close',
          subText:
            'This will remove the domain and object class association. Do you wish to proceed?',
        }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={async () => {
              await deleteCustomPropertiesFromDocument();
              toggleClearDocumentPropertiesDialog();
            }}
            text="Yes"
          />
          <DefaultButton
            onClick={toggleClearDocumentPropertiesDialog}
            text="No"
          />
        </DialogFooter>
      </Dialog>

      <Modal
        isOpen={isErrorModalOpen}
        onDismiss={hideErrorModal}
        containerClassName="error-modal__container"
        scrollableContentClassName="error-modal__scrollable"
      >
        <div className="error-modal__header">
          <h2>Errors</h2>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close"
            onClick={hideErrorModal}
            className="error-modal__close-button"
          />
        </div>

        <ul>
          {documentVerificationErrors.map((error) => (
            <li>{error}</li>
          ))}
        </ul>
      </Modal>
    </>
  );
};
