import { useMutation } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { VERIFY_DOCUMENT_TEMPLATE_ENDPOINT } from 'constants/endpoints';

import { useAxiosPrivate } from 'hooks/api/useAxiosPrivate';
import { useUserPreferencesQuery } from 'hooks/api/useUserPreferencesQuery.ts';

interface IRequestParams {
  axiosInstance: AxiosInstance;
  classId?: number | null;
  fileToken: string;
}

const verifyDocumentTemplateRequest = async ({
  axiosInstance,
  classId,
  fileToken,
}: IRequestParams): Promise<{ detail?: string | string[] }> => {
  const url = VERIFY_DOCUMENT_TEMPLATE_ENDPOINT.replace(
    ':class_id',
    classId ? classId.toString() : ''
  );

  const response = await axiosInstance.post(url, {
    template_file: fileToken,
  });

  return response?.data || {};
};

export const useVerifyDocumentTemplateMutation = () => {
  const { axiosInstance } = useAxiosPrivate();
  const { data: userPreferences } = useUserPreferencesQuery();

  return useMutation({
    mutationKey: ['verifyDocumentTemplate'],
    mutationFn: (fileToken: string) =>
      verifyDocumentTemplateRequest({
        axiosInstance,
        classId: userPreferences?.selectedObjectClassId,
        fileToken,
      }),
  });
};
