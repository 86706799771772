import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { USER_PREFERENCES_ENDPOINT } from 'constants/endpoints';

import { useAxiosPrivate } from 'hooks/api/useAxiosPrivate';

import { IUserPreferences } from 'types/IUserPreferences';

const getUserPreferencesRequest = async (axiosInstance: AxiosInstance) => {
  const response = await axiosInstance.get(USER_PREFERENCES_ENDPOINT);
  const preferences = response?.data?.preferences;

  return preferences ? JSON.parse(preferences) : {};
};

export const useUserPreferencesQuery = () => {
  const { axiosInstance } = useAxiosPrivate();

  return useQuery<IUserPreferences>({
    queryKey: ['userPreferences', axiosInstance.getUri()],
    queryFn: () => getUserPreferencesRequest(axiosInstance),
  });
};
