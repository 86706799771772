import { useMutation } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { UPLOAD_FILE_ENDPOINT } from 'constants/endpoints';

import { useAxiosPrivate } from 'hooks/api/useAxiosPrivate';

const uploadFileRequest = async (
  axiosInstance: AxiosInstance,
  payload: Blob
): Promise<string> => {
  const response = await axiosInstance.post(UPLOAD_FILE_ENDPOINT, payload, {
    headers: {
      'content-disposition':
        'attachment; filename*=UTF-8 document-template.docx',
    },
  });

  return response?.data?.token ?? '';
};

export const useUploadFileMutation = () => {
  const { axiosInstance } = useAxiosPrivate();

  return useMutation({
    mutationKey: ['uploadFile'],
    mutationFn: (payload: Blob) => uploadFileRequest(axiosInstance, payload),
  });
};
