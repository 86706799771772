import { CATALYST_CLASS, CATALYST_DOMAIN } from 'constants/common.ts';

import { useStore } from 'store';

import { deleteCustomPropertiesFromDocument } from 'utils/deleteCustomPropertiesFromDocument.ts';

export const readDocumentCustomProperties = async () => {
  return await Word.run(async (context) => {
    const catalystDomain =
      context.document.properties.customProperties.getItemOrNullObject(
        CATALYST_DOMAIN
      );

    const catalystClassId =
      context.document.properties.customProperties.getItemOrNullObject(
        CATALYST_CLASS
      );

    context.load(catalystDomain);
    context.load(catalystClassId);

    await context.sync();

    if (!catalystDomain.isNullObject && !catalystClassId.isNullObject) {
      useStore.getState().setDocumentCatalystDomain(catalystDomain.value);
      useStore.getState().setDocumentCatalystClassId(catalystClassId.value);
    } else {
      await deleteCustomPropertiesFromDocument();
    }
  });
};
