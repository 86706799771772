export const addCustomPropertyToDocument = async (
  propertyName: string,
  propertyValue?: string | number | null
) => {
  return await Word.run(async (context) => {
    context.document.properties.customProperties.add(
      propertyName,
      propertyValue
    );

    await context.sync();
  });
};
