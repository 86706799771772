export const insertTextToDocument = async (textToInsert?: string) => {
  if (!textToInsert) return;

  return await Word.run(async (context) => {
    const range = context.document.getSelection();
    range.insertText(textToInsert, Word.InsertLocation.end);

    // Synchronize the document state by executing the queued commands,
    // and return a promise to indicate task completion.
    await context.sync();
  });
};
