import { IFunctionData } from 'types/IFunctionData';

export const functionsData: Array<IFunctionData> = [
  {
    name: 'if',
    description: (
      <>
        The{' '}
        <span className="text-pink">
          {'{%'} if {'%}'}
        </span>{' '}
        statement is the primary building block to evaluate conditions. It can
        be combined with{' '}
        <span className="text-pink">
          {'{%'} elif {'%}'}
        </span>{' '}
        and{' '}
        <span className="text-pink">
          {'{%'} else {'%}'}
        </span>{' '}
        alongside other operators to create sophisticate document flows. The
        syntax is: <br />
        <br /> <span className="text-pink">{'{%'} if</span> {'<<'}
        evaluate one or more conditions {'>> '}
        <span className="text-pink">{'%}'}</span>
        <br />
        <span className="text-blue">
          {'>>'} Do this if the evaluation is true
        </span>
        <br />
        <span className="text-pink">
          {'{%'} else {'%}'}
        </span>
        <br />
        <span className="text-blue">
          {'>>'} Do this if the valuation is false
        </span>
        <br />
        <span className="text-pink">
          {'{%'} endif {'%}'}
        </span>
      </>
    ),
    example: [
      '{% if record.created_at < record.modified_at %}',
      'Record {{record.id}} was modified.',
      '{% else %}',
      'Record {{record.id}} was not modified.',
      '{% endif %}',
    ],
    code: ['{% if %}', '{% else %}', '{% endif %} '],
  },
  {
    name: 'for',
    description: (
      <>
        The{' '}
        <span className="text-pink">
          {'{%'} for {'%}'}
        </span>{' '}
        statement iterates (loops) over items in a list which could include a
        JSON object, string variable where{' '}
        <span className="text-pink">{'<reference>'}</span> is assigned a new
        value from <span className="text-pink">{'<variable>'}</span> on each
        iteration. The syntax is:
        <br />
        <br />
        <span className="text-pink">
          {'{%'} for {'<reference>'} in {'<variable>'} {'%}'}
        </span>
        <br />
        <span className="text-blue">
          {'>>'} Do this using{' '}
          <span className="text-pink">{'<reference>'}</span> for each iteration
        </span>
        <br />
        <span className="text-pink">
          {'{%'} endfor {'%}'}
        </span>
      </>
    ),
    example: [
      '{%  for matter_name in record.matter_json %}',
      'The matter name is {{matter_name}}',
      '{ % endfor %}',
    ],
    code: ['{% for %}', '{% endfor %}'],
  },
  {
    name: 'groupby',
    description: (
      <>
        The <span className="text-pink">groupby</span> statement iterates over
        items in a list (which could include a JSON object) allowing output to
        be grouped by one of the attributes. The{' '}
        <span className="text-pink">groupby</span> attribute is stored in the{' '}
        <span className="text-pink">grouper</span> reference. It requires a
        minimum of two{' '}
        <span className="text-pink">
          {'{%'} for {'%}'}
        </span>{' '}
        loops to implement. The syntax is:
        <br />
        <br />
        <span className="text-pink">
          {'{%'} for reference in variable|groupby(‘attribute’) {'%}'}
        </span>
        <br />
        <span className="text-blue">
          <span className="text-pink">{'{{group.grouper}}'}</span> {'>>'}output
          for each groupby attribute for each iteration
        </span>
        <br />
        <span className="text-pink">
          {'{%'} for reference in group.list {'%}'}
        </span>
        <br />
        <span className="text-blue">{'>>'} output for each iteration</span>
        <br />
        <span className="text-pink">
          {'{%'} endfor {'%}'}
        </span>
        <br />
        <span className="text-pink">
          {'{%'} endfor {'%}'}
        </span>
      </>
    ),
    example: [
      '{%  for group in record.matter_json | groupby(‘matter_name’) %}',
      'Claimant details for : {{matter_name}}',
      '{% for matter_detail in group.list %}',
      '{{matter.detail.claimant_firstname}}  {{matter.detail.claimant_surname}}  {{matter.detail.claimant_email}}',
      '{ % endfor %}',
      '{ % endfor %}',
    ],
    code: [
      '{% for group in |groupby(‘’) %}',
      '{% for in |group.list %}',
      '{% endfor %}',
      '{% endfor %}',
    ],
  },
  {
    name: 'divisibleby',
    description: (
      <>
        The <span className="text-pink">divisibleby</span> test allows you to
        test to see whether a given integer is evenly divisible by another (i.e.
        it is a factor). Often used within an{' '}
        <span className="text-pink">if</span> statement. The syntax (using{' '}
        <span className="text-pink">if</span> as the example) is: <br />
        <br />
        <span className="text-pink">{'{%'} if</span> {'<<'}a integer value{'>>'}{' '}
        <span className="text-pink">divisibleby(n) {'%}'}</span>
        <br />
        <span className="text-blue">
          {'>>'} Do this if the evaluation is true
        </span>
        <br />
        <span className="text-pink">
          {'{%'} else {'%}'}
        </span>
        <br />
        <span className="text-blue">
          {'>>'} Do this if the valuation is false
        </span>
        <br />
        <span className="text-pink">
          {'{%'} endif {'%}'}
        </span>
      </>
    ),
    code: ['divisibleby()'],
  },
  {
    name: 'unique',
    description: (
      <>
        The <span className="text-pink">unique</span> function iterates (loops)
        over items in a list to provide a unique (deduplicated) subset. Note, it
        cannot be used with JSON objects. The syntax is:
        <br />
        <br />
        <span className="text-pink">{'{{'}</span> {'<list>'}
        <span className="text-pink">|unique {'}}'}</span>
      </>
    ),
    example: ['{{[‘UK’,’US’,’SEA’,’US’,’JP,’’JP’,’UK’]|unique}}'],
    code: ['{{|unique}}'],
  },
  {
    name: 'iterable',
    description: (
      <>
        The <span className="text-pink">iterable</span> test allows you to test
        to see whether a given value can be iterated i.e. used in functions that
        can accept lists as inputs. Often used within an{' '}
        <span className="text-pink">if</span> statement. The syntax (using{' '}
        <span className="text-pink">if</span> as the example) is:
        <br />
        <br />
        <span className="text-pink">{'{%'} if</span> {'<<'}an unknown value
        {'>>'} <span className="text-pink">iterable(n) {'%}'}</span>
        <br />
        <span className="text-blue">
          {'>>'} Do this if the evaluation is true
        </span>
        <br />
        <span className="text-pink">
          {'{%'} else {'%}'}
        </span>
        <br />
        <span className="text-blue">
          {'>>'} Do this if the valuation is false
        </span>
        <br />
        <span className="text-pink">
          {'{%'} endif {'%}'}
        </span>
      </>
    ),
    code: ['iterable()'],
  },
  {
    name: 'integer',
    description: (
      <>
        The <span className="text-pink">integer</span> test allows you to test
        to see whether a given value is cast as a whole number (required for
        maths functions). Often used within an{' '}
        <span className="text-pink">if</span> statement. The syntax (using{' '}
        <span className="text-pink">if</span> as the example) is:
        <br />
        <br />
        <span className="text-pink">{'{%'} if</span> {'<<'} an unknown value
        {'>>'} <span className="text-pink">integer {'%}'}</span>
        <br />
        <span className="text-blue">
          {'>>'} Do this if the evaluation is true
        </span>
        <br />
        <span className="text-pink">
          {'{%'} else {'%}'}
        </span>
        <br />
        <span className="text-blue">
          {'>>'} Do this if the valuation is false
        </span>
        <br />
        <span className="text-pink">
          {'{%'} endif {'%}'}
        </span>
      </>
    ),
    code: ['integer'],
  },
  {
    name: 'float',
    description: (
      <>
        The <span className="text-pink">float</span> test allows you to test to
        see whether a given value is cast as a number with one of more decimal
        points. Often used within an <span className="text-pink">if</span>{' '}
        statement. The syntax (using <span className="text-pink">if</span> as
        the example) is:
        <br />
        <br />
        <span className="text-pink">{'{%'} if</span> {'<<'} an unknown value
        {'>>'} <span className="text-pink">float {'%}'}</span>
        <br />
        <span className="text-blue">
          {'>>'} Do this if the evaluation is true
        </span>
        <br />
        <span className="text-pink">
          {'{%'} else {'%}'}
        </span>
        <br />
        <span className="text-blue">
          {'>>'} Do this if the valuation is false
        </span>
        <br />
        <span className="text-pink">
          {'{%'} endif {'%}'}
        </span>
      </>
    ),
    code: ['float'],
  },
  {
    name: 'string',
    description: (
      <>
        The <span className="text-pink">string</span> test allows you to test to
        see whether a given value is cast as a string. Often used within an{' '}
        <span className="text-pink">if</span> statement. The syntax (using{' '}
        <span className="text-pink">if</span> as the example) is:
        <br />
        <br />
        <span className="text-pink">{'{%'} if</span> {'<<'} an unknown value
        {'>>'} <span className="text-pink">string {'%}'}</span>
        <br />
        <span className="text-blue">
          {'>>'} Do this if the evaluation is true
        </span>
        <br />
        <span className="text-pink">
          {'{%'} else {'%}'}
        </span>
        <br />
        <span className="text-blue">
          {'>>'} Do this if the valuation is false
        </span>
        <br />
        <span className="text-pink">
          {'{%'} endif {'%}'}
        </span>
      </>
    ),
    code: ['string'],
  },
  {
    name: 'trim',
    description: (
      <>
        The <span className="text-pink">trim</span> function allows you to
        remove leading and trailing white spaces from a string. The syntax is:
        <br />
        <br />
        <span className="text-pink">{'{{'}</span>
        {'<< a string value >>'}
        <span className="text-pink">| trim {'%}}'}</span>
      </>
    ),
    code: ['{% |trim %}'],
  },
  {
    name: 'replace',
    description: (
      <>
        The <span className="text-pink">replace</span> function allows you to
        replace one string with another. The syntax is:
        <br />
        <br />
        <span className="text-pink">{'{{'}</span>
        {'<< a string value >>'}
        <span className="text-pink">| replace(“</span>The value to find
        <span className="text-pink">”,”</span>The Value to insert
        <span className="text-pink">”){'}}'}</span>
      </>
    ),
    code: ['{% |replace(“”,””) %}'],
  },
  {
    name: 'capitalize',
    description: (
      <>
        The <span className="text-pink">capitalize</span> function allows you to
        capitalize the first letter of a string. The syntax is:
        <br />
        <br />
        <span className="text-pink">{'{{'}</span>
        {'<< a string value >>'}
        <span className="text-pink">| capitalize {'}}'}</span>
      </>
    ),
    code: ['{% |capitalize %}'],
  },
  {
    name: 'upper',
    description: (
      <>
        The <span className="text-pink">upper</span> function allows you to
        capitalize all values in a string to uppercase. The syntax is:
        <br />
        <br />
        <span className="text-pink">{'{{'}</span>
        {'<< a string value >>'}
        <span className="text-pink">| upper {'}}'}</span>
      </>
    ),
    code: ['{% |upper %}'],
  },
  {
    name: 'lower',
    description: (
      <>
        The <span className="text-pink">lower</span> function allows you to
        capitalize all values in a string to lowercase. The syntax is:
        <br />
        <br />
        <span className="text-pink">{'{{'}</span>
        {'<< a string value >>'}
        <span className="text-pink">| lower {'}}'}</span>
      </>
    ),
    code: ['{% |lower %}'],
  },
  {
    name: 'join',
    description: (
      <>
        The <span className="text-pink">join</span> function allows you to
        concatenate one or more strings into a single string. You can optionally
        also specify a separator. The syntax is:
        <br />
        <br />
        <span className="text-pink">{'{{'}</span>
        {'<< a string value(s) >>'}
        <span className="text-pink">| join (‘separator’) {'}}'}</span>
      </>
    ),
    code: ['{% |join(‘’) %}'],
  },
  {
    name: 'length',
    description: (
      <>
        The <span className="text-pink">length</span> function allows you to
        determine the number of characters contained within a value. The syntax
        is:
        <br />
        <br />
        <span className="text-pink">{'{{'}</span>
        {'<< a string value >>'}
        <span className="text-pink">| length {'}}'}</span>
      </>
    ),
    code: ['{% |length %}'],
  },
];
