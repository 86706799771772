export enum EObjectClassFieldType {
  Int = 'int',
  Float = 'float',
  Bool = 'bool',
  String = 'string',
  Enum = 'enum',
  Set = 'set',
  Email = 'email',
  Phone = 'phone',
  Date = 'date',
  Time = 'time',
  Datetime = 'datetime',
  Url = 'url',
  Json = 'json',
  Document = 'document',
  User = 'user',
}
