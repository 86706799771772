import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useCallback, useRef } from 'react';

import { Spinner } from '@fluentui/react';

import { ObjectClassFieldsItem } from 'components/ObjectClassFieldsItem';

import { useObjectClassFieldsInfiniteQuery } from 'hooks/api/useObjectClassFieldsInfiniteQuery';

import { useStore } from 'store';

import { IObjectClassField } from 'types/IObjectClassField';

import './ObjectClassFieldsList.styles.scss';

interface IObjectClassFieldsList {
  searchPhrase?: string;
}

export const ObjectClassFieldsList = ({
  searchPhrase,
}: IObjectClassFieldsList) => {
  const mergeFieldsClassFilter = useStore(
    (state) => state.mergeFieldsClassFilter
  );

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isError,
    error,
  } = useObjectClassFieldsInfiniteQuery({
    searchPhrase,
    selectedObjectClass: mergeFieldsClassFilter,
  });

  const intObserver = useRef<IntersectionObserver>();
  const lastItemRef = useCallback(
    (item: HTMLDivElement) => {
      if (isLoading || isFetching) {
        return;
      }

      if (intObserver.current) {
        intObserver.current.disconnect();
      }

      intObserver.current = new IntersectionObserver(async (items) => {
        if (items[0].isIntersecting && hasNextPage) {
          await fetchNextPage();
        }
      });

      if (item) {
        intObserver.current.observe(item);
      }
    },
    [isLoading, isFetching, hasNextPage, fetchNextPage, intObserver]
  );

  const classFields = useMemo(() => {
    let mappedClassFields: Array<IObjectClassField> = [];

    data?.pages?.forEach((page) => {
      if (page?.results !== undefined) {
        mappedClassFields = [...mappedClassFields, ...page.results];
      }
    });

    return mappedClassFields;
  }, [data?.pages]);

  if (isError) {
    const errorText =
      (error as AxiosError<unknown>)?.response?.status === 403
        ? 'You do not have access to fields of this Object class.'
        : 'Something went wrong, please try again later.';

    return (
      <p className="object-class-fields-list__error-message">{errorText}</p>
    );
  }

  return (
    <div className="object-class-fields-list">
      {isLoading && <Spinner size={1} />}
      {!isLoading && classFields?.length > 0 && (
        <>
          {classFields.map((objectClassField, idx) => {
            if (classFields.length === idx + 1) {
              return (
                <ObjectClassFieldsItem
                  ref={lastItemRef}
                  key={objectClassField.id}
                  objectClassField={objectClassField}
                />
              );
            }

            return (
              <ObjectClassFieldsItem
                key={objectClassField.id}
                objectClassField={objectClassField}
              />
            );
          })}
        </>
      )}
      {!isLoading && classFields?.length === 0 && (
        <p>No object class fields found</p>
      )}
    </div>
  );
};
