export const LOGIN_ENDPOINT = '/single-sign-on/login/ms-obo/';
export const REFRESH_TOKEN_ENDPOINT = '/users/refresh-token/';
export const OBJECT_CLASS_ENDPOINT = '/object-classes/:class_id/';
export const OBJECT_CLASSES_ENDPOINT = '/object-classes/autocomplete/';
export const OBJECT_CLASS_FIELDS_ENDPOINT = '/object-classes/:class_id/fields/';
export const USER_PREFERENCES_ENDPOINT = '/users/preferences/wordAddin/';
export const API_VERSION_ENDPOINT = '/version/';
export const UPLOAD_FILE_ENDPOINT = '/files/upload/';
export const VERIFY_DOCUMENT_TEMPLATE_ENDPOINT =
  '/object-classes/:class_id/document-templates/verify/';
export const RELATIONAL_CLASSES_ENDPOINT =
  '/object-models/1/object-class/:class_id/children/';
