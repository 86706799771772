import { useLocation, useNavigate } from 'react-router-dom';

import { PrimaryButton } from '@fluentui/react/lib/Button';

import { CATALYST_CLASS_ERROR, CATALYST_DOMAIN_ERROR } from 'constants/common';

import { routes } from 'router';

import { useStore } from 'store';

import { deleteCustomPropertiesFromDocument } from 'utils/deleteCustomPropertiesFromDocument';

import './LoginErrorPage.styles.scss';

export const LoginErrorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = async () => {
    await deleteCustomPropertiesFromDocument();
    navigate(routes.login);
  };

  return (
    <div className="login-error-page__content-container">
      {location?.state?.errorType === CATALYST_DOMAIN_ERROR ? (
        <p>
          Looks like you don't have access to domain{' '}
          <b>{useStore.getState().documentCatalystDomain}</b>. You can continue
          to use this template by clicking NEXT to choose an alternative domain
          and class. Please note this won't remove any existing merge fields
          which will have to be manually changed.
        </p>
      ) : null}

      {location?.state?.errorType === CATALYST_CLASS_ERROR ? (
        <p>
          Looks like you don't have access to a class saved in the document. You
          can continue to use this template by clicking NEXT to choose an
          alternative domain and class. Please note this won't remove any
          existing merge fields which will have to be manually changed.
        </p>
      ) : null}

      <div className="login-error-page__button-container">
        <PrimaryButton onClick={handleClick}>Next</PrimaryButton>
      </div>
    </div>
  );
};
