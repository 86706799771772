import { Outlet } from 'react-router-dom';

import { Spinner } from '@fluentui/react';

import { BorderLine } from 'components/BorderLine';
import { Header } from 'components/Header';

import { useStore } from 'store';

import './Layout.styles.scss';

interface ILayoutProps {
  hideNavButton?: boolean;
}

export const Layout = ({ hideNavButton }: ILayoutProps) => {
  const showLoaderOverlay = useStore((state) => state.showLoaderOverlay);

  return (
    <div className="app ms-Fabric">
      <BorderLine />
      <div className="layout__container">
        <Header hideNavButton={hideNavButton} />

        <div className="layout__content-container">
          <Outlet />
        </div>
      </div>

      {showLoaderOverlay ? (
        <div className="overlay">
          <Spinner size={3} />
        </div>
      ) : null}
    </div>
  );
};
