import './BorderLine.styles.scss';

export const BorderLine = () => {
  return (
    <div className="border-line">
      <div className="border-line__thick-line border-line__thick-line--first" />
      <div className="border-line__thin-line border-line__thin-line--second" />
      <div className="border-line__thin-line border-line__thin-line--third" />
    </div>
  );
};
