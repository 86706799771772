import axios from 'axios';

import { useStore } from 'store';

import { formatApiUrl } from 'utils/formatApiUrl';

export const useAxiosPublic = () => {
  const apiBaseUrl = useStore((state) => state.apiBaseUrl);

  const axiosInstance = axios.create({
    baseURL: formatApiUrl(apiBaseUrl),
  });

  return { axiosInstance };
};
