import debounce from 'debounce-promise';
import { Dispatch, SetStateAction, useRef } from 'react';
import { InputActionMeta, SingleValue } from 'react-select';
import Select from 'react-select';

import { ISelectOption } from 'types/ISelectOption';

interface IObjectClassSelect {
  selectedObjectClass: SingleValue<ISelectOption>;
  setSelectObjectClass: (
    selectedObjectClass: SingleValue<ISelectOption>
  ) => void;
  setSearchPhrase: Dispatch<SetStateAction<string>>;
  objectClasses?: ISelectOption[];
  isLoading: boolean;
  disabled: boolean;
}

export const ObjectClassSelect = ({
  selectedObjectClass,
  setSelectObjectClass,
  setSearchPhrase,
  objectClasses,
  isLoading,
  disabled,
}: IObjectClassSelect) => {
  const handleSearchDebounced = useRef(
    debounce((searchPhrase: string) => setSearchPhrase(searchPhrase), 300)
  ).current;

  const handleInputChange = (inputText: string, meta: InputActionMeta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      void handleSearchDebounced(inputText);
    }
  };

  return (
    <Select<ISelectOption>
      value={selectedObjectClass}
      placeholder="Search for object class"
      options={objectClasses}
      getOptionLabel={(option) => option.text}
      noOptionsMessage={() => 'No matches found'}
      isLoading={isLoading}
      onChange={(newValue) => setSelectObjectClass(newValue)}
      onInputChange={handleInputChange}
      filterOption={null}
      isDisabled={disabled}
    />
  );
};
