import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { REFRESH_TOKEN_ENDPOINT } from 'constants/endpoints';

import { useAxiosPublic } from 'hooks/api/useAxiosPublic';

import { routes } from 'router';

import { useStore } from 'store';

import { formatApiUrl } from 'utils/formatApiUrl';

export const useAxiosPrivate = () => {
  const navigate = useNavigate();
  const { axiosInstance: axiosInstancePublic } = useAxiosPublic();

  const [apiBaseUrl, setAccessToken, isRenewingToken, setIsRenewingToken] =
    useStore(
      (state) => [
        state.apiBaseUrl,
        state.setAccessToken,
        state.isRenewingToken,
        state.setIsRenewingToken,
      ],
      shallow
    );

  const axiosInstance = axios.create({
    baseURL: formatApiUrl(apiBaseUrl),
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      if (useStore.getState().accessToken) {
        config.headers.Authorization = `JWT ${useStore.getState().accessToken}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  let intervalId: ReturnType<typeof setInterval> | undefined = undefined;

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalConfig = error.config;

      if (error.response.status === 401) {
        if (!isRenewingToken) {
          return new Promise(async (resolve, reject) => {
            // updates the state in store so other failed API with 401 error doesn't get to call the refresh token request
            setIsRenewingToken(true);

            try {
              const { data } = await axiosInstancePublic.post(
                REFRESH_TOKEN_ENDPOINT,
                {
                  refresh: useStore.getState().refreshToken,
                }
              );

              setAccessToken(data?.access);
              await useStore.persist.rehydrate();
              resolve(axiosInstance(originalConfig));
            } catch {
              // Navigate to login page if refresh token is invalid or expired
              reject();
              navigate(routes.login, {
                state: {
                  showFullPageLoader: true,
                },
              });
            } finally {
              setIsRenewingToken(false);
            }
          });
        } else {
          // if there is a current refresh token request, it waits for that to finish and use the updated token data to retry the API call so there will be no additional refresh token requests
          return new Promise((resolve) => {
            intervalId = setInterval(() => {
              if (!isRenewingToken) {
                clearInterval(intervalId);
                resolve(axiosInstance(originalConfig));
              }
            }, 100);
          });
        }
      }

      return Promise.reject(error);
    }
  );

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  return { axiosInstance };
};
