import { useMemo } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Spinner } from '@fluentui/react';

import { FunctionsTabPanel } from 'components/FunctionsTabPanel';
import { ObjectClassFieldsTabPanel } from 'components/ObjectClassFieldsTabPanel';

import { useObjectClassQuery } from 'hooks/api/useObjectClassQuery';
import { useSelectedRelationalClassesQueries } from 'hooks/api/useSelectedRelationalClassesQueries.ts';
import { useUserPreferencesQuery } from 'hooks/api/useUserPreferencesQuery';
import { useRedirectToLogin } from 'hooks/useRedirectToLogin';
import { useRedirectToSettings } from 'hooks/useRedirectToSettings';

import { IMergeFieldsClassFilter } from 'types/ISelectOption.ts';

import './HomePage.styles.scss';

export const HomePage = () => {
  const { data: userPreferences, isLoading: isLoadingUserPreferences } =
    useUserPreferencesQuery();
  const {
    data: selectedObjectClass,
    isInitialLoading: isLoadingSelectedObjectClass,
  } = useObjectClassQuery(userPreferences?.selectedObjectClassId);
  const {
    data: selectedRelationalClasses,
    isLoading: isLoadingSelectedRelationalClasses,
  } = useSelectedRelationalClassesQueries(
    userPreferences?.selectedRelationalClassesIds
  );

  const objectClassSelectOptions = useMemo(() => {
    if (isLoadingSelectedObjectClass && isLoadingSelectedRelationalClasses) {
      return [];
    }

    const concatenatedObjectClassSelectOptions: IMergeFieldsClassFilter[] = [];

    if (
      selectedObjectClass?.id !== undefined &&
      selectedObjectClass?.name !== undefined
    ) {
      concatenatedObjectClassSelectOptions.push({
        id: selectedObjectClass.id,
        name: selectedObjectClass.name,
        isRelationalClass: false,
      });
    }

    return concatenatedObjectClassSelectOptions.concat(
      selectedRelationalClasses
    );
  }, [
    selectedObjectClass,
    selectedRelationalClasses,
    isLoadingSelectedObjectClass,
    isLoadingSelectedRelationalClasses,
  ]);

  useRedirectToLogin();
  useRedirectToSettings();

  const isLoading =
    isLoadingUserPreferences ||
    isLoadingSelectedObjectClass ||
    isLoadingSelectedRelationalClasses;

  return (
    <>
      {isLoading ? (
        <div className="loader-wrapper">
          <Spinner size={3} />
        </div>
      ) : (
        <Tabs>
          <TabList>
            <Tab>Object class fields</Tab>
            <Tab>Functions</Tab>
          </TabList>

          <TabPanel>
            <ObjectClassFieldsTabPanel
              objectClassSelectOptions={objectClassSelectOptions}
            />
          </TabPanel>
          <TabPanel>
            <FunctionsTabPanel />
          </TabPanel>
        </Tabs>
      )}
    </>
  );
};
