import CheckboxIcon from 'assets/checkbox.svg';
import DateIcon from 'assets/date.svg';
import EmailIcon from 'assets/email.svg';
import JsonIcon from 'assets/json.svg';
import LinkIcon from 'assets/link.svg';
import MultiSelectIcon from 'assets/multiSelect.svg';
import NumberIcon from 'assets/number.svg';
import PersonIcon from 'assets/person.svg';
import PhoneIcon from 'assets/phone.svg';
import SingleSelectIcon from 'assets/singleSelect.svg';
import TextIcon from 'assets/text.svg';
import TimeIcon from 'assets/time.svg';
import UploadIcon from 'assets/upload.svg';

import { EObjectClassFieldType } from 'types/ObjectClassFieldType';

export const getIconBasedOnFieldType = (fieldType: EObjectClassFieldType) => {
  switch (fieldType) {
    case EObjectClassFieldType.Int:
    case EObjectClassFieldType.Float:
      return NumberIcon;
    case EObjectClassFieldType.Bool:
      return CheckboxIcon;
    case EObjectClassFieldType.String:
      return TextIcon;
    case EObjectClassFieldType.Enum:
      return SingleSelectIcon;
    case EObjectClassFieldType.Set:
      return MultiSelectIcon;
    case EObjectClassFieldType.Email:
      return EmailIcon;
    case EObjectClassFieldType.Phone:
      return PhoneIcon;
    case EObjectClassFieldType.Date:
    case EObjectClassFieldType.Datetime:
      return DateIcon;
    case EObjectClassFieldType.Time:
      return TimeIcon;
    case EObjectClassFieldType.Url:
      return LinkIcon;
    case EObjectClassFieldType.Json:
      return JsonIcon;
    case EObjectClassFieldType.Document:
      return UploadIcon;
    case EObjectClassFieldType.User:
      return PersonIcon;
    default:
      return TextIcon;
  }
};
