import { AxiosResponse } from 'axios';

import { IErrorResponse } from 'types/IErrorResponse';

export const getErrorMessageFromResponse = (
  response: AxiosResponse<IErrorResponse>
) => {
  if (response.status === 403) {
    return response?.data?.detail;
  }

  if (response.status === 400) {
    return response?.data?.template_file;
  }

  return undefined;
};
