import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { RELATIONAL_CLASSES_ENDPOINT } from 'constants/endpoints';

import { useAxiosPrivate } from 'hooks/api/useAxiosPrivate';

import { IObjectClass } from 'types/IObjectClass.ts';
import { ITreeNode } from 'types/ITree.ts';

interface IRequestParams {
  axiosInstance: AxiosInstance;
  classId?: string;
}

export const getRelationalClassesRequest = async ({
  axiosInstance,
  classId,
}: IRequestParams) => {
  const url = RELATIONAL_CLASSES_ENDPOINT.replace(':class_id', classId ?? '');

  const response = await axiosInstance.get<IObjectClass[] | undefined>(url);

  const mappedData = response?.data?.reduce(
    (mappedRelationalClasses, relationalClass) => {
      if (relationalClass.id && relationalClass.name) {
        mappedRelationalClasses.push({
          name: relationalClass.name,
          id: relationalClass.id,
        });
      }

      return mappedRelationalClasses;
    },
    [] as ITreeNode[]
  );

  return mappedData ?? [];
};

export const useRelationalClassesQuery = (classId?: string) => {
  const { axiosInstance } = useAxiosPrivate();

  return useQuery({
    queryKey: ['relationalClasses', classId, axiosInstance.getUri()],
    queryFn: () => getRelationalClassesRequest({ axiosInstance, classId }),
    enabled: classId !== undefined,
  });
};
