import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { USER_PREFERENCES_ENDPOINT } from 'constants/endpoints';

import { useAxiosPrivate } from 'hooks/api/useAxiosPrivate';

import { IUserPreferences } from 'types/IUserPreferences';

const updateUserPreferencesRequest = async (
  axiosInstance: AxiosInstance,
  preferences: IUserPreferences
) => {
  return await axiosInstance.post(USER_PREFERENCES_ENDPOINT, {
    preferences: JSON.stringify(preferences),
  });
};

export const useUpdateUserPreferencesMutation = () => {
  const queryClient = useQueryClient();
  const { axiosInstance } = useAxiosPrivate();

  return useMutation({
    mutationKey: ['updateUserPreferences'],
    mutationFn: (preferences: IUserPreferences) =>
      updateUserPreferencesRequest(axiosInstance, preferences),
    onSuccess: async () =>
      await queryClient.invalidateQueries({ queryKey: ['userPreferences'] }),
  });
};
