import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAxiosPrivate } from 'hooks/api/useAxiosPrivate.ts';

import { IObjectClass } from 'types/IObjectClass.ts';
import { IMergeFieldsClassFilter } from 'types/ISelectOption.ts';

import { getObjectClassRequest } from './useObjectClassQuery.ts';

export const useSelectedRelationalClassesQueries = (
  classesIds?: number[] | null
) => {
  const { axiosInstance } = useAxiosPrivate();

  const queries = useQueries({
    queries:
      classesIds?.map((classId) => ({
        queryKey: [
          'relationalClass',
          { classId, domain: axiosInstance.getUri() },
        ],
        queryFn: (): Promise<IObjectClass> =>
          getObjectClassRequest({ axiosInstance, classId }),
        staleTime: Infinity,
      })) ?? [],
  });

  const isLoading = queries.some((query) => query.isLoading === true);
  const data = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return queries.reduce(
      (mappedSelectedRelationalClasses, relationalClassQueryResult) => {
        if (
          relationalClassQueryResult?.data?.id &&
          relationalClassQueryResult?.data?.name
        ) {
          mappedSelectedRelationalClasses.push({
            id: relationalClassQueryResult.data.id,
            name: relationalClassQueryResult.data.name,
            isRelationalClass: true,
          });
        }

        return mappedSelectedRelationalClasses;
      },
      [] as IMergeFieldsClassFilter[]
    );
  }, [isLoading, queries]);

  return { isLoading, data };
};
