import { Navigate, Outlet } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { routes } from 'router';

import { useStore } from 'store';

export const RequireAuth = () => {
  const [apiBaseUrl, accessToken, refreshToken] = useStore(
    (state) => [state.apiBaseUrl, state.accessToken, state.refreshToken],
    shallow
  );

  const isLoggedIn = !!(apiBaseUrl && accessToken && refreshToken);

  return isLoggedIn ? <Outlet /> : <Navigate to={routes.login} />;
};
