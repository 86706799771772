import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { OBJECT_CLASSES_ENDPOINT } from 'constants/endpoints';

import { useAxiosPrivate } from 'hooks/api/useAxiosPrivate';

import { ISelectOption } from 'types/ISelectOption';

export const getObjectClassesRequest = async (
  axiosInstance: AxiosInstance,
  searchPhrase?: string
) => {
  const response = await axiosInstance.get(OBJECT_CLASSES_ENDPOINT, {
    params: { text__icontains: searchPhrase },
  });

  return response?.data?.results ?? [];
};

export const useObjectClassesQuery = (searchPhrase?: string) => {
  const { axiosInstance } = useAxiosPrivate();

  return useQuery<ISelectOption[]>({
    queryKey: ['objectClasses', searchPhrase, axiosInstance.getUri()],
    queryFn: () => getObjectClassesRequest(axiosInstance, searchPhrase),
  });
};
