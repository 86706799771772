import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { OBJECT_CLASS_FIELDS_ENDPOINT } from 'constants/endpoints';

import { useAxiosPrivate } from 'hooks/api/useAxiosPrivate';

import { IObjectClass } from 'types/IObjectClass.ts';
import { IObjectClassField } from 'types/IObjectClassField';
import { IPaginatedResponse } from 'types/IPaginatedResponse';

interface IHookParams {
  limit?: number;
  searchPhrase?: string;
  selectedObjectClass?: IObjectClass;
}

interface IRequestParams extends IHookParams {
  pageParam?: number;
  selectedObjectClassId?: number | null;
  axiosInstance: AxiosInstance;
}

const defaultLimit = 50;

const getObjectClassFieldsRequest = async ({
  axiosInstance,
  searchPhrase,
  selectedObjectClassId,
  limit = defaultLimit,
  pageParam = 0,
}: IRequestParams) => {
  const url = OBJECT_CLASS_FIELDS_ENDPOINT.replace(
    ':class_id',
    selectedObjectClassId ? selectedObjectClassId.toString() : ''
  );

  const response = await axiosInstance.get(url, {
    params: { limit, label__icontains: searchPhrase, offset: pageParam },
  });

  return response?.data ?? [];
};

export const useObjectClassFieldsInfiniteQuery = ({
  searchPhrase,
  selectedObjectClass,
  limit = defaultLimit,
}: IHookParams) => {
  const { axiosInstance } = useAxiosPrivate();

  return useInfiniteQuery<IPaginatedResponse<IObjectClassField>>({
    queryKey: [
      'objectClassFields',
      selectedObjectClass?.id,
      searchPhrase,
      limit,
      axiosInstance.getUri(),
    ],
    queryFn: ({ pageParam }) =>
      getObjectClassFieldsRequest({
        pageParam,
        selectedObjectClassId: selectedObjectClass?.id,
        searchPhrase,
        limit,
        axiosInstance,
      }),
    getNextPageParam: (lastPage) => {
      if (!lastPage.next) {
        return undefined;
      }

      return lastPage.next.split('offset=').pop();
    },
    enabled: selectedObjectClass?.id !== undefined,
  });
};
