import { useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

import { Tree } from 'components/Tree';

import { useAxiosPrivate } from 'hooks/api/useAxiosPrivate.ts';
import { useObjectClassQuery } from 'hooks/api/useObjectClassQuery.ts';
import {
  getRelationalClassesRequest,
  useRelationalClassesQuery,
} from 'hooks/api/useRelationalClassesQuery';
import { useUpdateUserPreferencesMutation } from 'hooks/api/useUpdateUserPreferencesMutation.ts';
import { useUserPreferencesQuery } from 'hooks/api/useUserPreferencesQuery.ts';

import { routes } from 'router';

import { useStore } from 'store';

import { ITreeNode } from 'types/ITree.ts';

import { formatApiUrl } from 'utils/formatApiUrl.ts';

import './RelationalClassesPage.styles.scss';

export const RelationalClassesPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { axiosInstance } = useAxiosPrivate();

  const [checkedClassesIds, setCheckedClassesIds] = useState<number[]>([]);

  const selectedObjectClass = useStore((state) => state.selectedObjectClass);

  const { data: parentClass } = useObjectClassQuery(selectedObjectClass?.value);
  const { data: relationalClasses } = useRelationalClassesQuery(
    selectedObjectClass?.value?.toString()
  );
  const { data: userPreferences, isInitialLoading: isLoadingUserPreferences } =
    useUserPreferencesQuery();

  const { mutateAsync: updateUserPreferences } =
    useUpdateUserPreferencesMutation();

  const areLocalAndRemoteClassesEqual =
    selectedObjectClass?.value === userPreferences?.selectedObjectClassId;

  useEffect(() => {
    if (
      !isLoadingUserPreferences &&
      userPreferences?.selectedRelationalClassesIds &&
      areLocalAndRemoteClassesEqual
    ) {
      setCheckedClassesIds(userPreferences.selectedRelationalClassesIds);
    }
  }, [
    isLoadingUserPreferences,
    userPreferences,
    selectedObjectClass,
    areLocalAndRemoteClassesEqual,
  ]);

  const onSubmit = async () => {
    const preferences = {
      selectedObjectClassId: selectedObjectClass?.value,
      selectedRelationalClassesIds: checkedClassesIds,
    };

    try {
      await updateUserPreferences(preferences);
      toast.success('Object class and relational classes have been saved!');
      navigate(routes.settings);
    } catch {
      // TODO: add error handling
      console.log('error');
    }
  };

  const handleOnCheck = (id: number) => {
    setCheckedClassesIds((prevState) => {
      const updatedState = [...prevState];
      const foundClassIndex = updatedState.indexOf(id);

      if (foundClassIndex === -1) {
        updatedState.push(id);
      } else {
        updatedState.splice(foundClassIndex, 1);
      }

      return updatedState;
    });
  };

  const getNewTreeData = (
    treeData: ITreeNode[],
    expandedClassId: number,
    children: ITreeNode[]
  ) => {
    const loopTreeNodes = (data: ITreeNode[]) => {
      for (let i = 0; i < data.length; i++) {
        if (expandedClassId === data[i].id) {
          data[i] = {
            ...data[i],
            children,
            isLeaf: children.length === 0,
            wasDataFetched: true,
          };
          break;
        }
        if (expandedClassId !== data[i].id) {
          const treeNodeChildren = data[i].children;

          if (treeNodeChildren) {
            loopTreeNodes(treeNodeChildren);
          }
        }
      }
    };

    loopTreeNodes(treeData);
  };

  const onLoadData = async (treeNode: ITreeNode): Promise<void> => {
    try {
      const data = await getRelationalClassesRequest({
        axiosInstance,
        classId: treeNode.id.toString(),
      });

      if (relationalClasses) {
        const updatedTreeData = produce(relationalClasses, (draftState) => {
          getNewTreeData(draftState, treeNode.id, data);
        });

        queryClient.setQueryData(
          [
            'relationalClasses',
            selectedObjectClass?.value?.toString(),
            formatApiUrl(useStore.getState().apiBaseUrl),
          ],
          updatedTreeData
        );
      }
    } catch {
      //TODO: error handling
    }
  };

  const resetRelationalClasses = async () => {
    if (
      userPreferences?.selectedRelationalClassesIds &&
      userPreferences.selectedRelationalClassesIds.length > 0
    ) {
      const preferences = {
        ...userPreferences,
        selectedRelationalClassesIds: [],
      };

      try {
        await updateUserPreferences(preferences);
      } catch {
        // TODO: add error handling
        console.log('error');
      }
    } else {
      setCheckedClassesIds([]);
    }
  };

  return (
    <div className="relational-classes__page-container">
      <h1 className="relational-classes__page-header">Relationships</h1>

      <p className="relational-classes__page-header-info">
        Select the related object classes you would like to make available to
        this document.
      </p>

      {relationalClasses ? (
        <Tree
          parentClass={parentClass}
          treeData={relationalClasses}
          loadData={onLoadData}
          onCheck={handleOnCheck}
          checkedClassesIds={checkedClassesIds}
        />
      ) : null}

      <div className="relational-classes__button-container">
        <DefaultButton onClick={() => navigate(routes.settings)}>
          Cancel
        </DefaultButton>
        <PrimaryButton onClick={resetRelationalClasses}>Reset</PrimaryButton>
        <PrimaryButton onClick={onSubmit}>Save</PrimaryButton>
      </div>
    </div>
  );
};
