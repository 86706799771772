import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PrimaryButton } from '@fluentui/react/lib/Button';

import { createManifestString } from 'utils/createManifestString';

import './ManifestPage.styles.scss';

export const ManifestPage = () => {
  const { applicationId } = useParams();

  const getXmlDocument = () => {
    if (applicationId) {
      const xmlStr = createManifestString(applicationId);
      const blob = new Blob([xmlStr], {
        type: 'application/xml',
      });
      const xmlURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = xmlURL;
      link.setAttribute('download', `manifest.xml`);
      link.click();
      URL.revokeObjectURL(xmlURL);
    }
  };

  useEffect(() => {
    if (applicationId) {
      getXmlDocument();
    }
  }, [applicationId]);

  return (
    <div className="manifest-page__container">
      <p>
        Your download will begin shortly. If it doesn't, press the button below.
      </p>

      <div className="manifest-page__button-wrapper">
        <PrimaryButton onClick={getXmlDocument}>
          Download manifest
        </PrimaryButton>
      </div>
    </div>
  );
};
