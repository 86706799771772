import { AxiosError } from 'axios';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { Spinner } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';

import { CATALYST_DOMAIN_ERROR } from 'constants/common';
import { LOGIN_ENDPOINT } from 'constants/endpoints';

import { useAxiosPublic } from 'hooks/api/useAxiosPublic';

import { routes } from 'router';

import { useStore } from 'store';

import { IErrorResponse } from 'types/IErrorResponse';
import { IOfficeErrorResponse } from 'types/IOfficeErrorResponse';

import { getErrorMessageFromResponse } from 'utils/getErrorMessageFromResponse';

import './LoginPage.styles.scss';

export const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { axiosInstance } = useAxiosPublic();

  const [inputValue, setInputValue] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const officeAccessToken = useRef<string>();

  const [
    apiBaseUrl,
    setApiBaseUrl,
    setAccessToken,
    setRefreshToken,
    documentCatalystDomain,
  ] = useStore(
    (state) => [
      state.apiBaseUrl,
      state.setApiBaseUrl,
      state.setAccessToken,
      state.setRefreshToken,
      state.documentCatalystDomain,
    ],
    shallow
  );

  const getOfficeAuthToken = async () => {
    try {
      const accessToken = await OfficeRuntime.auth.getAccessToken({
        allowSignInPrompt: true,
        allowConsentPrompt: true,
      });

      officeAccessToken.current = accessToken;
      return;
    } catch (error) {
      setApiBaseUrl();

      const errorResponse = error as IOfficeErrorResponse;
      setIsLoading(false);
      return errorResponse;
    }
  };

  const getAccessAndRefreshTokens = useCallback(async () => {
    try {
      const { data } = await axiosInstance.post(
        LOGIN_ENDPOINT,
        {},
        {
          headers: {
            Authorization: officeAccessToken.current,
          },
        }
      );

      if (data) {
        setAccessToken(data.access);
        setRefreshToken(data.refresh);
        navigate(routes.home);
      }
    } catch (error) {
      setApiBaseUrl();

      const errorResponse = (error as AxiosError<IErrorResponse>)?.response;
      const errorMessage =
        errorResponse && getErrorMessageFromResponse(errorResponse);

      if (apiBaseUrl === documentCatalystDomain) {
        navigate(routes.loginError, {
          state: {
            errorType: CATALYST_DOMAIN_ERROR,
          },
        });
      }

      if (errorMessage) {
        typeof errorMessage === 'string' && setError(errorMessage);
      } else {
        setError('Invalid URL');
      }
    } finally {
      setIsLoading(false);
    }
  }, [axiosInstance]);

  const login = useCallback(async () => {
    setIsLoading(true);
    setError(undefined);

    let officeTokenRequestError: IOfficeErrorResponse | undefined;

    if (!officeAccessToken?.current) {
      officeTokenRequestError = await getOfficeAuthToken();
    }

    if (officeTokenRequestError) {
      setError(officeTokenRequestError?.message);
      setIsLoading(false);
    } else {
      await getAccessAndRefreshTokens();
    }
  }, [getAccessAndRefreshTokens]);

  const formatUrl = (url: string) => {
    const trimmedUrl = url.trim();
    const urlWithRemovedPrefix = trimmedUrl.replace(/^https?:\/\//, '');
    return urlWithRemovedPrefix.replace(/\/+$/, '');
  };

  const changeApiBaseUrl = () => {
    inputValue
      ? setApiBaseUrl(formatUrl(inputValue))
      : setApiBaseUrl(inputValue);
  };

  useLayoutEffect(() => {
    if (documentCatalystDomain && apiBaseUrl !== documentCatalystDomain) {
      setIsLoading(true);
      setApiBaseUrl(documentCatalystDomain);
    } else if (apiBaseUrl) {
      void login();
    }
  }, [apiBaseUrl, documentCatalystDomain]);

  const changeInputValue = (newValue?: string) => {
    setInputValue(newValue);

    if (error) {
      setError(undefined);
    }
  };

  if (isLoading && location?.state?.showFullPageLoader) {
    return (
      <div className="loader-wrapper">
        <Spinner size={3} />
      </div>
    );
  }

  return (
    <div>
      <TextField
        label="Catalyst domain"
        placeholder="Type the URL of your domain"
        defaultValue={apiBaseUrl}
        value={inputValue}
        onChange={(_, newValue) => changeInputValue(newValue)}
        errorMessage={error}
        prefix="https://"
      />

      <div className="login-page__button-container">
        <div className="login-page__login-button-wrapper">
          <PrimaryButton
            className={`login-page__login-button ${
              isLoading ? 'is-loading' : ''
            }`}
            onClick={changeApiBaseUrl}
            disabled={isLoading || !inputValue || !!error}
          >
            Next
          </PrimaryButton>

          {isLoading ? (
            <div className="login-page__login-button-spinner-wrapper">
              <Spinner size={1} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
