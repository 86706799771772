import { useEffect, useState } from 'react';
import Select from 'react-select';
import { shallow } from 'zustand/shallow';

import { Footer } from 'components/Footer';
import { ObjectClassFieldsList } from 'components/ObjectClassFieldsList';
import { SearchBox } from 'components/SearchBox';

import { useStore } from 'store';

import { IMergeFieldsClassFilter } from 'types/ISelectOption.ts';

import './ObjectClassFieldsTabPanel.styles.scss';

interface IObjectClassFieldsTabPanel {
  objectClassSelectOptions: IMergeFieldsClassFilter[];
}
export const ObjectClassFieldsTabPanel = ({
  objectClassSelectOptions,
}: IObjectClassFieldsTabPanel) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [
    mergeFieldsClassFilter,
    setMergeFieldsClassFilter,
    setSelectedObjectClassField,
  ] = useStore(
    (state) => [
      state.mergeFieldsClassFilter,
      state.setMergeFieldsClassFilter,
      state.setSelectedObjectClassField,
    ],
    shallow
  );

  /* Set default class filter / change it when currently selected class is unchecked in relative classes tree */
  useEffect(() => {
    if (!mergeFieldsClassFilter) {
      setMergeFieldsClassFilter(objectClassSelectOptions[0]);
    } else if (
      !objectClassSelectOptions.some(
        (option) => option.id === mergeFieldsClassFilter.id
      )
    ) {
      setMergeFieldsClassFilter(objectClassSelectOptions[0]);
      setSelectedObjectClassField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="homepage__fields-section">
      <Select<IMergeFieldsClassFilter>
        options={objectClassSelectOptions}
        value={mergeFieldsClassFilter}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id.toString()}
        onChange={(newValue) => {
          setMergeFieldsClassFilter(newValue as IMergeFieldsClassFilter);
          setSelectedObjectClassField();
        }}
      />
      <SearchBox
        className="fields-section__search-box"
        onChange={setSearchPhrase}
      />
      <ObjectClassFieldsList searchPhrase={searchPhrase} />
      <Footer />
    </section>
  );
};
