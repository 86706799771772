import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from 'router';

import { useStore } from 'store';

export const useRedirectToLogin = () => {
  const navigate = useNavigate();

  const apiBaseUrl = useStore((state) => state.apiBaseUrl);
  const documentCatalystDomain = useStore(
    (state) => state.documentCatalystDomain
  );

  useEffect(() => {
    if (
      documentCatalystDomain !== undefined &&
      documentCatalystDomain !== apiBaseUrl
    ) {
      navigate(routes.login, {
        state: {
          showFullPageLoader: true,
        },
      });
    }
  }, [documentCatalystDomain, apiBaseUrl]);
};
