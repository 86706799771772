import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { API_VERSION_ENDPOINT } from 'constants/endpoints';

import { useAxiosPrivate } from 'hooks/api/useAxiosPrivate';

import { IApiVersion } from 'types/IApiVersion';

const getApiVersionRequest = async (axiosInstance: AxiosInstance) => {
  const response = await axiosInstance.get(API_VERSION_ENDPOINT);
  return response?.data ?? {};
};

export const useApiVersionQuery = () => {
  const { axiosInstance } = useAxiosPrivate();

  return useQuery<IApiVersion>({
    queryKey: ['apiVersion', axiosInstance.getUri()],
    queryFn: () => getApiVersionRequest(axiosInstance),
  });
};
