import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CATALYST_CLASS_ERROR } from 'constants/common';

import { useObjectClassQuery } from 'hooks/api/useObjectClassQuery';
import { useUpdateUserPreferencesMutation } from 'hooks/api/useUpdateUserPreferencesMutation';
import { useUserPreferencesQuery } from 'hooks/api/useUserPreferencesQuery';

import { routes } from 'router';

import { useStore } from 'store';

export const useRedirectToSettings = () => {
  const navigate = useNavigate();

  const documentCatalystClassId = useStore(
    (state) => state.documentCatalystClassId
  );

  const { data: userPreferences, isLoading: isLoadingUserPreferences } =
    useUserPreferencesQuery();
  const { isInitialLoading: isLoadingSelectedObjectClass, isError } =
    useObjectClassQuery(userPreferences?.selectedObjectClassId);

  const { mutateAsync: updateUserPreferences } =
    useUpdateUserPreferencesMutation();

  // Redirect user to settings page if a class id isn't present in a Word document or on BE
  useEffect(() => {
    if (
      !isLoadingUserPreferences &&
      !userPreferences?.selectedObjectClassId &&
      !documentCatalystClassId
    ) {
      navigate(routes.settings);
    }
  }, [isLoadingUserPreferences, documentCatalystClassId, userPreferences]);

  // If there's a class id saved in document's custom properties, and it doesn't match what is saved on BE send a post request to BE
  useEffect(() => {
    if (
      !isLoadingUserPreferences &&
      documentCatalystClassId &&
      documentCatalystClassId !== userPreferences?.selectedObjectClassId
    ) {
      const preferences = {
        ...userPreferences,
        selectedObjectClassId: documentCatalystClassId,
      };

      void updateUserPreferences(preferences);
    }
  }, [isLoadingUserPreferences, documentCatalystClassId, userPreferences]);

  // Redirect user to settings page if user doesn't have access to object class that was saved in user preferences on BE
  useEffect(() => {
    if (
      !isLoadingUserPreferences &&
      !isLoadingSelectedObjectClass &&
      isError &&
      !documentCatalystClassId
    ) {
      (async () => {
        const preferences = {
          ...userPreferences,
          selectedObjectClassId: null,
        };

        await updateUserPreferences(preferences);
        navigate(routes.settings);
      })();
    }
  }, [
    isLoadingUserPreferences,
    isLoadingSelectedObjectClass,
    isError,
    updateUserPreferences,
    userPreferences,
    navigate,
    documentCatalystClassId,
  ]);

  // Redirect user to error page if user doesn't have access to object class that was saved in Word document
  useEffect(() => {
    if (
      !isLoadingUserPreferences &&
      !isLoadingSelectedObjectClass &&
      isError &&
      documentCatalystClassId !== undefined
    ) {
      navigate(routes.loginError, {
        state: {
          errorType: CATALYST_CLASS_ERROR,
        },
      });
    }
  }, [
    isLoadingUserPreferences,
    isLoadingSelectedObjectClass,
    isError,
    navigate,
    documentCatalystClassId,
  ]);
};
