import { ForwardedRef, forwardRef, useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { useSaveClassAndDomainInDocument } from 'hooks/useSaveClassAndDomainInDocument.ts';

import { useStore } from 'store';

import { getIconBasedOnFieldType } from 'utils/getIconBasedOnFieldType';
import { readDocumentCustomProperties } from 'utils/readDocumentCustomProperties.ts';

import './ObjectClassFieldsItem.styles.scss';
import { ObjectClassFieldsItemProps } from './interface';

export const ObjectClassFieldsItem = forwardRef(function ObjectClassFieldsItem(
  { objectClassField }: ObjectClassFieldsItemProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const [selectedObjectClassField, setSelectedObjectClassField] = useStore(
    (state) => [
      state.selectedObjectClassField,
      state.setSelectedObjectClassField,
    ],
    shallow
  );

  const { saveClassAndDomainInDocument } = useSaveClassAndDomainInDocument();

  const handleInsertTextToDocument = async () => {
    await saveClassAndDomainInDocument();
    await readDocumentCustomProperties();
  };

  const { label, type } = objectClassField;

  const handleClick = useCallback(() => {
    setSelectedObjectClassField(objectClassField);
  }, [objectClassField, setSelectedObjectClassField]);

  return (
    <div
      ref={ref}
      className={`object-class-fields-item common__clickable ${
        selectedObjectClassField === objectClassField
          ? 'object-class-fields-item--active'
          : ''
      }`}
      onClick={handleClick}
      onDoubleClick={handleInsertTextToDocument}
    >
      <img
        className="list-item__icon"
        src={getIconBasedOnFieldType(type)}
        alt="class field icon"
      />
      <p className="object-class-fields-item__label">{label}</p>
    </div>
  );
});
