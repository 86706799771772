import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { Spinner } from '@fluentui/react';

import { router } from 'router';

import { readDocumentCustomProperties } from 'utils/readDocumentCustomProperties';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 10 * (60 * 1000), // 10 min
      staleTime: 9.5 * (60 * 1000),
      retry: (failureCount, error) => {
        const errorStatus = (error as AxiosError)?.response?.status;

        if (errorStatus && [401, 403, 404].includes(errorStatus)) {
          return false;
        } else if (failureCount > 1) {
          return false;
        }
        return true;
      },
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  const [isReadingDocument, setIsReadingDocument] = useState(true);

  useEffect(() => {
    (async () => {
      if (!isReadingDocument) {
        setIsReadingDocument(true);
      }
      try {
        await readDocumentCustomProperties();
      } finally {
        setIsReadingDocument(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isReadingDocument ? (
    <div className="loader-wrapper">
      <Spinner size={3} />
    </div>
  ) : (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ToastContainer theme="colored" />
    </QueryClientProvider>
  );
};
