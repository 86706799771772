import { useState } from 'react';

import { Spinner } from '@fluentui/react';

import ChevronRightIcon from 'assets/chevron-right-solid.svg';
import { ReactComponent as CircleCheckIcon } from 'assets/circle-check-solid.svg';
import CircleIcon from 'assets/circle-regular.svg';

import { ITreeNodeProps } from 'types/ITree.ts';

import './TreeNode.styles.scss';

export const TreeNode = ({
  treeNodeData,
  loadData,
  onCheck,
  level,
  checkedClassesIds,
}: ITreeNodeProps) => {
  const { name, id, children } = treeNodeData;
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const isChecked = checkedClassesIds.includes(id);
  const isLeaf = treeNodeData.isLeaf === true;
  const isToggleable = !isLeaf && !isLoading;

  const handleToggleTreeBranch = async () => {
    try {
      if (!treeNodeData.wasDataFetched) {
        setIsLoading(true);
        await loadData({ name, id });
      }
      setIsOpen((prevState) => !prevState);
    } catch {
      //TODO: handle errors
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className={`tree-node__container ${
          isChecked ? 'tree-node__container-selected' : ''
        }`}
      >
        <div className="tree-node__check-container" onClick={() => onCheck(id)}>
          {isChecked ? (
            <CircleCheckIcon className="tree-node__check tree-node__check--blue" />
          ) : (
            <img
              src={CircleIcon}
              alt="relative-class-check"
              className="tree-node__check"
            />
          )}
        </div>

        <span style={{ flex: `0 0 ${12 * level}px` }} />

        <div
          className="tree-node__title-container"
          onClick={isToggleable ? handleToggleTreeBranch : undefined}
        >
          <div className="tree-node__icon-container">
            {isToggleable ? (
              <span
                className={`tree-node__switcher ${
                  isOpen ? 'tree-node__switcher-open' : ''
                }`}
              >
                <img src={ChevronRightIcon} alt="chevron-right" />
              </span>
            ) : null}

            {isLoading ? <Spinner size={0} /> : null}
          </div>

          <p className="tree-node__title text-ellipsis">{name}</p>
        </div>
      </div>

      {!isLoading && isOpen ? (
        <>
          {children?.map((child) => (
            <TreeNode
              treeNodeData={child}
              key={child.id}
              loadData={loadData}
              onCheck={onCheck}
              level={level + 1}
              checkedClassesIds={checkedClassesIds}
            />
          ))}
        </>
      ) : null}
    </>
  );
};
