export const routes = {
  home: '/',
  login: '/login',
  settings: '/settings',
  loginError: '/login-error',
  relationalClasses: '/relational-classes/:classId',
};

export const browserRoutes = {
  getManifest: '/getManifest/:applicationId',
};
