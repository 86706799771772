import { SingleValue } from 'react-select';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { IFunctionData } from 'types/IFunctionData';
import { IObjectClassField } from 'types/IObjectClassField';
import { IMergeFieldsClassFilter, ISelectOption } from 'types/ISelectOption.ts';

interface IStore {
  apiBaseUrl?: string;
  setApiBaseUrl: (newBaseUrl?: string) => void;
  accessToken?: string;
  setAccessToken: (newAccessToken?: string) => void;
  refreshToken?: string;
  setRefreshToken: (newRefreshToken?: string) => void;
  isRenewingToken: boolean;
  setIsRenewingToken: (isRenewingToken: boolean) => void;
  selectedObjectClassField?: IObjectClassField;
  setSelectedObjectClassField: (classField?: IObjectClassField) => void;
  documentCatalystDomain?: string;
  setDocumentCatalystDomain: (documentCatalystDomain?: string) => void;
  documentCatalystClassId?: number;
  setDocumentCatalystClassId: (documentCatalystClassId?: number) => void;
  selectedFunction?: IFunctionData;
  setSelectedFunction: (functionData: IFunctionData) => void;
  showLoaderOverlay: boolean;
  setShowLoaderOverlay: (showLoaderOverlay: boolean) => void;
  mergeFieldsClassFilter?: IMergeFieldsClassFilter;
  setMergeFieldsClassFilter: (
    mergeFieldsClassFilter: IMergeFieldsClassFilter
  ) => void;
  selectedObjectClass: SingleValue<ISelectOption>;
  setSelectedObjectClass: (
    selectedObjectClass: SingleValue<ISelectOption>
  ) => void;
}

export const useStore = create<IStore>()(
  persist(
    (set) => ({
      apiBaseUrl: undefined,
      setApiBaseUrl: (newBaseUrl) => set({ apiBaseUrl: newBaseUrl }),
      accessToken: undefined,
      setAccessToken: (newAccessToken) => set({ accessToken: newAccessToken }),
      refreshToken: undefined,
      setRefreshToken: (newRefreshToken) =>
        set({ refreshToken: newRefreshToken }),
      isRenewingToken: false,
      setIsRenewingToken: (bool) => set({ isRenewingToken: bool }),
      selectedObjectClassField: undefined,
      setSelectedObjectClassField: (classField) =>
        set({ selectedObjectClassField: classField }),
      selectedFunction: undefined,
      setSelectedFunction: (functionData) =>
        set({ selectedFunction: functionData }),
      documentCatalystDomain: undefined,
      setDocumentCatalystDomain: (documentCatalystDomain) =>
        set({ documentCatalystDomain }),
      documentCatalystClassId: undefined,
      setDocumentCatalystClassId: (documentCatalystClassId) =>
        set({ documentCatalystClassId }),
      showLoaderOverlay: false,
      setShowLoaderOverlay: (showLoaderOverlay) => set({ showLoaderOverlay }),
      mergeFieldsClassFilter: undefined,
      setMergeFieldsClassFilter: (mergeFieldsClassFilter) =>
        set({ mergeFieldsClassFilter }),
      selectedObjectClass: null,
      setSelectedObjectClass: (selectedObjectClass) =>
        set({ selectedObjectClass }),
    }),
    {
      name: 'store', // name of the item in local storage
      partialize: (state) => ({
        apiBaseUrl: state.apiBaseUrl,
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
      }),
    }
  )
);
