import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { PrimaryButton } from '@fluentui/react/lib/Button';

import { OverflowTooltip } from 'components/OverflowTooltip';

import { useSaveClassAndDomainInDocument } from 'hooks/useSaveClassAndDomainInDocument.ts';

import { useStore } from 'store';

import { formatInsertedObjectClassField } from 'utils/formatInsertedObjectClassField.ts';
import { getIconBasedOnFieldType } from 'utils/getIconBasedOnFieldType';
import { mapClassFieldType } from 'utils/mapClassFieldType';
import { readDocumentCustomProperties } from 'utils/readDocumentCustomProperties.ts';

import './Footer.styles.scss';

export const Footer = () => {
  const [selectedObjectClassField, mergeFieldsClassFilter] = useStore(
    (state) => [state.selectedObjectClassField, state.mergeFieldsClassFilter],
    shallow
  );

  const formattedFieldValue = useMemo(() => {
    return formatInsertedObjectClassField(
      selectedObjectClassField,
      mergeFieldsClassFilter
    );
  }, [selectedObjectClassField, mergeFieldsClassFilter]);

  const { saveClassAndDomainInDocument } = useSaveClassAndDomainInDocument();

  const handleInsertTextToDocument = async () => {
    await saveClassAndDomainInDocument();
    await readDocumentCustomProperties();
  };

  return (
    <div className="footer__container">
      <div className="footer__field-info-container">
        {selectedObjectClassField && (
          <>
            <div className="footer__icon-and-label-container">
              <img
                className="footer__field-icon"
                src={getIconBasedOnFieldType(selectedObjectClassField.type)}
                alt="class field icon"
              />

              <OverflowTooltip tooltipContent={selectedObjectClassField.label}>
                <p className="footer__field-label text-ellipsis">
                  {selectedObjectClassField.label}
                </p>
              </OverflowTooltip>
            </div>

            <p className="footer__text-gray">
              {mapClassFieldType(selectedObjectClassField.type)}
            </p>

            <p className="footer__text-gray text-ellipsis">
              {formattedFieldValue}
            </p>
          </>
        )}
      </div>

      <div className="footer__button-wrapper">
        <PrimaryButton
          disabled={selectedObjectClassField === undefined}
          onClick={handleInsertTextToDocument}
        >
          Insert
        </PrimaryButton>
      </div>
    </div>
  );
};
