import { CATALYST_CLASS, CATALYST_DOMAIN } from 'constants/common.ts';

import { useStore } from 'store';

export const deleteCustomPropertiesFromDocument = async () => {
  return await Word.run(async (context) => {
    const catalystDomain =
      context.document.properties.customProperties.getItemOrNullObject(
        CATALYST_DOMAIN
      );

    const catalystClassId =
      context.document.properties.customProperties.getItemOrNullObject(
        CATALYST_CLASS
      );

    catalystDomain?.delete();
    catalystClassId?.delete();

    await context.sync();

    useStore.getState().setDocumentCatalystDomain();
    useStore.getState().setDocumentCatalystClassId();
  });
};
