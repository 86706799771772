import { IconButton } from '@fluentui/react/lib/Button';

import JsonIcon from 'assets/json.svg';

import { useStore } from 'store';

import { IFunctionData } from 'types/IFunctionData';

import { insertTextToDocument } from 'utils/insertTextToDocument';

import './FunctionsListItem.styles.scss';

interface IFunctionsListItem {
  functionData: IFunctionData;
  toggleDrawer: () => void;
}

export const FunctionsListItem = ({
  functionData,
  toggleDrawer,
}: IFunctionsListItem) => {
  const setSelectedFunction = useStore((state) => state.setSelectedFunction);

  const handleToggleDrawer = () => {
    setSelectedFunction(functionData);
    toggleDrawer();
  };

  return (
    <div className="functions__item common__clickable">
      <div
        className="functions__item-title-container"
        onDoubleClick={() => insertTextToDocument(functionData.code.join('\n'))}
      >
        <img src={JsonIcon} alt="function-icon" className="list-item__icon" />
        <p className="functions__item-title">{functionData.name}</p>
      </div>

      <IconButton
        iconProps={{ iconName: 'Info' }}
        className="list-item-info-button"
        onClick={handleToggleDrawer}
      />
    </div>
  );
};
