import { shallow } from 'zustand/shallow';

import { CATALYST_CLASS, CATALYST_DOMAIN } from 'constants/common.ts';

import { useUserPreferencesQuery } from 'hooks/api/useUserPreferencesQuery.ts';

import { useStore } from 'store';

import { addCustomPropertyToDocument } from 'utils/addCustomPropertyToDocument.ts';
import { formatInsertedObjectClassField } from 'utils/formatInsertedObjectClassField.ts';
import { insertTextToDocument } from 'utils/insertTextToDocument.ts';

export const useSaveClassAndDomainInDocument = () => {
  const [apiBaseUrl, selectedObjectClassField, mergeFieldsClassFilter] =
    useStore(
      (state) => [
        state.apiBaseUrl,
        state.selectedObjectClassField,
        state.mergeFieldsClassFilter,
      ],
      shallow
    );

  const { data: userPreferences } = useUserPreferencesQuery();

  const saveClassAndDomainInDocument = async () => {
    try {
      await insertTextToDocument(
        formatInsertedObjectClassField(
          selectedObjectClassField,
          mergeFieldsClassFilter
        )
      );
      await addCustomPropertyToDocument(CATALYST_DOMAIN, apiBaseUrl);
      await addCustomPropertyToDocument(
        CATALYST_CLASS,
        userPreferences?.selectedObjectClassId
      );
    } catch (error) {
      console.log('error', { error });
    }
  };

  return { saveClassAndDomainInDocument };
};
