import { ReactNode, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

interface IOverflowTooltip {
  children: ReactNode;
  tooltipContent: string;
}

export const OverflowTooltip = ({
  children,
  tooltipContent,
}: IOverflowTooltip) => {
  const textElementRef = useRef<HTMLAnchorElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = () => {
    if (textElementRef.current) {
      const clientWidth = textElementRef.current.getBoundingClientRect().width;

      textElementRef.current.style.overflow = 'visible';
      const contentWidth = textElementRef.current.getBoundingClientRect().width;
      textElementRef.current.style.overflow = 'hidden';

      setIsOverflowing(contentWidth > clientWidth);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <>
      <a
        data-tooltip-id="footer-tooltip"
        data-tooltip-hidden={!isOverflowing}
        ref={textElementRef}
        className="text-ellipsis"
      >
        {children}
      </a>

      <Tooltip
        id="footer-tooltip"
        content={tooltipContent}
        variant="info"
        place="top"
        className="custom-tooltip"
      />
    </>
  );
};
