import debounce from 'debounce-promise';

import { SearchBox as FluentSearchBox } from '@fluentui/react';

import { SearchBoxProps } from './interface';

export const SearchBox = ({ className, onChange }: SearchBoxProps) => {
  const handleInputChange = debounce((value: string) => onChange(value), 300);

  return (
    <FluentSearchBox
      className={`search-box ${className}`}
      placeholder="Search fields"
      onChange={(e) => handleInputChange(e?.target?.value ?? '')}
    />
  );
};
