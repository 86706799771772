import { NavLink } from 'react-router-dom';

import { ReactComponent as GearIcon } from 'assets/gear.svg';
import Logo from 'assets/logo.svg';

import { routes } from 'router';

import './Header.styles.scss';

interface IHeaderProps {
  hideNavButton?: boolean;
}

export const Header = ({ hideNavButton = false }: IHeaderProps) => {
  return (
    <div className="header">
      <div className="header__polygon-left" />
      <div className="header__polygon-right" />
      <img className="header__logo" src={Logo} alt="logo" />
      {hideNavButton ? null : (
        <NavLink to={routes.settings} className="header__settings-nav">
          <GearIcon />
        </NavLink>
      )}
    </div>
  );
};
