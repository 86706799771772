export const getWordDocumentBlob = async (): Promise<Blob> => {
  return new Promise((resolve, reject) =>
    Office.context.document.getFileAsync(
      Office.FileType.Compressed,
      { sliceSize: 65536 /*64 KB*/ },
      async (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const wordFile = result.value;
          const slices = await getAllSlices(wordFile);
          const bytes = new Uint8Array(slices);

          resolve(
            new Blob([bytes], {
              type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            })
          );
        } else {
          reject(result.error.message);
        }
      }
    )
  );
};

const getAllSlices = (file: Office.File): Promise<Array<number>> => {
  let isError = false;

  return new Promise(async (resolve, reject) => {
    let documentSlices: Array<number> = [];

    for (
      let sliceIndex = 0;
      sliceIndex < file.sliceCount && !isError;
      sliceIndex++
    ) {
      const sliceReadPromise: Promise<void> = new Promise(
        (sliceResolve, sliceReject) => {
          file.getSliceAsync(
            sliceIndex,
            (asyncResult: Office.AsyncResult<Office.Slice>) => {
              if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                documentSlices = documentSlices.concat(asyncResult.value.data);

                sliceResolve();
              } else {
                file.closeAsync();

                sliceReject();
              }
            }
          );
        }
      );

      try {
        await sliceReadPromise;
      } catch {
        isError = true;
      }
    }

    if (isError || !documentSlices.length) {
      reject('Error while reading document. Please try it again.');
      return;
    }

    file.closeAsync();

    resolve(documentSlices);
  });
};
