import './NotFoundPage.styles.scss';

export const NotFoundPage = () => {
  return (
    <div className="not-found-page__container">
      <p className="not-found-page__message">
        The Autologyx office add-in is only accessible from within an Office
        app.
      </p>
    </div>
  );
};
